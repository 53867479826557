<template>
  <div class="content">
    <base-header class="pb-6" type="primary">
      <div class="row align-items-center py-4">
      </div>
    </base-header>
    <div class="container-fluid mt--6 mb-9">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0">Staff</h3>
              </div>
              <div class="col-6 text-right">
                 <el-tooltip content="Add a staff member" placement="top">
                    <base-button type="primary" size="sm" @click="modals.staffModal = true">
                      <span class="btn-inner--icon"><i class="ni ni-fat-add"></i></span>
                      <span class="btn-inner--text">Add staff</span>
                    </base-button>
                 </el-tooltip>

                 <el-tooltip content="Import staff data from CSV file." placement="top">
                    <base-button type="primary" size="sm" @click="modals.staffDataUpload = true">
                      <span class="btn-inner--icon"><i class="fas fa-file-upload"></i></span>
                      <span class="btn-inner--text">Import staff</span>
                    </base-button>
                 </el-tooltip>
               
                <el-tooltip content="Export" placement="top">
                  <download-csv
                    :data='tableData'
                    name="staffList.csv"
                    :labels="labels"
                    :fields="fields"
                  class="btn base-button el-tooltip btn-icon btn-fab btn-neutral btn-sm"
                  >
                    Download
                    <span class="btn-inner--icon"><i class="fas fa-file-export"></i></span>
                  </download-csv>
                </el-tooltip>
              </div>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select tableSelectShadow"
                v-model="pagination.perPage"
                placeholder="Per page">

                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input alternative v-model="searchQuery"
                    prepend-icon="fas fa-search"
                    placeholder="Search by name...">
                </base-input>
              </div>
            </div>
            <el-table :data="queriedData"
                row-key="id"
                ref="staffTable"
                header-row-class-name="thead-light"
                @sort-change="sortChange">
              <!-- avatar colum -->
              <el-table-column label="Name" prop="first_name last_name" sortable min-width="155">
                <template v-slot="{row}">
                  <div class="avatar avatar-sm rounded-circle mr-2 bg-primary">
                    <b>{{row.first_name[0].toUpperCase()}}</b>
                  </div>
                  {{row.first_name}} {{row.last_name}}
                </template>
              </el-table-column>
               <!-- Phone number-->
              <el-table-column label="Phone" prop="staff_phone" min-width="100">
                <template v-slot="{row}">
                    {{row.staff_phone}}
                </template>
              </el-table-column>
              <!-- Code -->
              <el-table-column label="Code" prop="code" min-width="80">
                <template v-slot="{row}">
                    {{row.code}}
                </template>
              </el-table-column>
               <!-- email -->
              <el-table-column label="Email" prop="staff_email" min-width="150" sortable>
                <template v-slot="{row}">
                    {{row.staff_email}}
                </template>
              </el-table-column>

              <!-- branch-->
              <el-table-column label="Branch" prop="name" min-width="150" sortable>
                <template v-slot="{row}">
                    {{row.name}}
                </template>
              </el-table-column>
             
              <!-- status -->
              <!-- <el-table-column label="Status" prop="status" min-width="80">
                <template v-slot="{row}">
                    <badge rounded type="primary" class="badge-success">
                        <span class="status">{{row.status}}</span>
                    </badge>
                </template>
              </el-table-column> -->
              <!-- edit view -->
              <el-table-column type="expand">
                
                <template slot-scope="props">
                  <div>
                    <base-button size="sm" type="neutral" @click="veiwDeatail(props.row)">close</base-button>
                  </div>
                  <div class="p-4">
                    <div class="row mb-4">
                      <!-- first colum -->
                      <div class="col-lg-3 col-md-4 col-sm-12">
                        <div class="row">
                          <div class="col">
                            <span class="h6 surtitle text-muted">First name</span>
                            <quick-edit v-model="props.row.first_name"  @input="onUpdateEmployeeDetail(props.row.user_id, props.row.first_name,'first_name')" :classes="quickEditClasses"></quick-edit>
                          </div>
                        </div>
                      </div>
                      <!-- second colum -->
                      <div class="col-lg-3 col-md-4 col-sm-12">
                        <div class="row">
                          <div class="col">
                            <span class="h6 surtitle text-muted">Last name</span>
                            <quick-edit v-model="props.row.last_name" @input="onUpdateEmployeeDetail(props.row.user_id, props.row.last_name,'last_name')" :classes="quickEditClasses"></quick-edit>
                          </div>
                        </div>
                      </div>
                      <!-- last row -->
                      <div class="col-lg-3 col-md-4 col-sm-12">
                        <div class="row">
                          <div class="col">
                            <span class="h6 surtitle text-muted">Phone number</span>
                            <quick-edit v-model="props.row.staff_phone" @input="onUpdateEmployeeDetail(props.row.user_id, props.row.staff_phone,'phone_number')" :classes="quickEditClasses"></quick-edit>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row">
                      <!-- first colum -->
                      <div class="col-lg-3 col-md-3 col-sm-12">
                        <div class="row">
                          <div class="col">
                            <span class="h6 surtitle text-muted">Email</span>
                            <quick-edit v-model="props.row.staff_email" @input="onUpdateEmployeeDetail(props.row.user_id, props.row.staff_email,'email')" :classes="quickEditClasses"></quick-edit>
                          </div>
                        </div>
                      </div>
                      <!-- second colum -->
                      <div class="col-lg-3 col-md-3 col-sm-12">
                        <div class="row">
                          <div class="col">
                            <span class="h6 surtitle text-muted">Branch</span><br>
                            <el-select v-model="props.row.branch_id"
                              placeholder="Select branch"
                              :loading="loading"
                              :remote-method="fetchCompanyBranches"
                              :remote="true"
                              required
                              class="col-12 px-0 mb-3"
                              @change="updateUserBranch(props.row.user_id, props.row.branch_id)">
                              <el-option
                                v-for="(branch, index) in branches"
                                :key="branch.index"
                                :label="branch.name"
                                :value="branch.id">
                              </el-option>
                            </el-select>
                            <!-- <quick-edit v-model="props.row.last_name" :classes="quickEditClasses"></quick-edit> -->
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3 col-md-3 col-sm-12">
                        <div class="row">
                          <div class="col">
                            <span class="h6 surtitle text-muted">Code</span>
                            <span class="d-block h3">{{props.row.code}}</span>
                            <!-- <quick-edit v-model="props.row.staff_phone" :classes="quickEditClasses"></quick-edit> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </template>
              </el-table-column>
              <!-- actions -->
              <el-table-column label="Actions" min-width="80">
                <div slot-scope="scope" class="table-actions">
                  <el-tooltip content="View & edit" placement="top">
                    <base-button outline size="sm" type="primary"  class="btn-icon-only rounded-circle badge-primary" @click="veiwDeatail(scope.row)">
                        <span class="btn-inner--icon"><i class="fas fa-user-edit"></i></span>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Delete" placement="top">
                    <base-button outline size="sm" type="danger"  class="btn-icon-only rounded-circle badge-danger" @click="onDelete(scope.row)">
                        <span class="btn-inner--icon"><i class="fas fa-trash"></i></span>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>

            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
      <!-- Add staff modal -->
      <div>
       <modal :show.sync="modals.staffModal"
               body-classes="px-5 pb-5"
               header-classes="pt-4 px-lg-5 pb-0"
               gradient="secondary"
               modal-classes="modal-dialog-centered">
               <div class="row mb-4">
                  <div class="col text-muted">
                      <h2>
                        Add
                        <small class="text-muted"> a new staff</small>
                      </h2>
                  </div>
                 
                  <div class="col-auto">
                    <base-button @click="modals.staffModal = false" outline type="danger" size="sm" class="btn-icon-only rounded-circle badge-danger border-0">
                      <span class="btn-inner--icon"><i class="fas fa-times"></i></span>
                    </base-button>
                  </div>
               </div>
              
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                            class="mb-3"
                            prepend-icon="ni ni ni-circle-08"
                            placeholder="First name"
                            name="First name"
                            :rules="{required: true}"
                            v-model="model.firstname">
                  </base-input>
                  <base-input alternative
                            class="mb-3"
                            prepend-icon="ni ni ni-circle-08"
                            placeholder="Last name"
                            name="Last name"
                            :rules="{required: true}"
                            v-model="model.lastname">
                  </base-input>
                  <base-input alternative
                        type="tel"
                        class="mb-3"
                        prepend-icon="fas fa-phone"
                        placeholder="Phone number"
                        name="Phone number"
                        :rules="{required: true}"
                        v-model="model.phonenumber">
                  </base-input>

                  <base-input alternative
                          class="mb-3"
                          prepend-icon="ni ni-email-83"
                          placeholder="Email"
                          name="Email"
                          :rules="{email: true}"
                          v-model="model.email">
                  </base-input>
                  <div>
                    <el-select v-model="model.branch" 
                      clearable 
                      placeholder="Select branch"
                      :loading="loading"
                      :remote-method="fetchCompanyBranches"
                      :remote="true"
                      required
                      class="col-12 px-0 mb-3">
                      <el-option
                        v-for="(branch, index) in branches"
                        :key="branch.index"
                        :label="branch.name"
                        :value="branch.id">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="branchInvalid" class="invalid-feedback mt--2" style="display: block;"> Kindly select a branch</div>
                   
                  <div slot="footer" class="text-right">
                    <base-button type="primary" nativeType="submit" style="border-radius: 40px">Save</base-button>
                  </div>
                </form>
            </validation-observer>
        </modal>
      </div>
      <!-- staff data import modal -->
      <div>
       <modal :show.sync="modals.staffDataUpload"
               body-classes="px-5 pb-5"
               header-classes="pt-4 px-lg-5 pb-0"
               gradient="secondary"
               modal-classes="modal-dialog-centered">
               <div class="row mb-2">
                  <div class="col text-muted">
                      <h2>
                        Import
                        <small class="text-muted"> staff data from CSV file.</small>
                      </h2>
                  </div>
                 
                  <div class="col-auto">
                    <base-button @click="closeImportModal" outline type="danger" size="sm" class="btn-icon-only rounded-circle badge-danger border-0">
                      <span class="btn-inner--icon"><i class="fas fa-times"></i></span>
                    </base-button>
                  </div>
               </div>
               
               <div class="text-muted mb-3">
                 <span class="h6 surtitle text-muted">CSV file (required)</span>
                <hr class="my-2">
                <small class="text-muted font-italic">
                  File must contain these columns: first_name (required),last_name (required),phone_number (required) and email.
                  or Download this sample <a href="https://kaziapps.com/sample/staff_data_sample.csv" download="staff_data_sample"><b>.csv file</b></a> to guide you.
                </small>
              </div>

              <div class="text-center mb-3">
                <base-button class=""  type="primary"  @click="handleClick">
                  <span class="btn-inner--text">Select file</span>
                </base-button>
                <input type="file" ref="upload" style="display: none" @change="fileChange"/><br>
                <small class="text-muted"><b>{{file != null ? this.file.name : '' }}</b></small>
                <div style="width: 100%; margin-top: 0.25rem; font-size: 80%; color: #fb6340;" v-if="fileNotSelected"> Kindly select a .csv file</div>
              </div>

              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onImport)">
                  <div>
                    <el-select v-model="model.branch" 
                      clearable 
                      placeholder="Select branch"
                      :loading="loading"
                      :remote-method="fetchCompanyBranches"
                      :remote="true"
                      required
                      class="col-12 px-0 mb-3">
                      <el-option
                        v-for="(branch, index) in branches"
                        :key="branch.index"
                        :label="branch.name"
                        :value="branch.id">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="branchInvalid" class="invalid-feedback mt--2" style="display: block;"> Kindly select a branch</div>
                   
                  <div slot="footer" class="text-right">
                    <base-button type="primary" nativeType="submit" style="border-radius: 40px">Import data</base-button>
                  </div>
                </form>
            </validation-observer>
        </modal>
      </div>


      <!-- failed data Inmport modal -->
      <div>
       <modal :show.sync="modals.failedDataImport"
               body-classes="px-5 pb-5"
               header-classes="pt-4 px-lg-5 pb-0"
               gradient="secondary"
               modal-classes="modal-dialog-centered">
               <div class="row mb-2">
                  <div class="col text-muted">
                      <h2>
                        Import
                        <small class="text-muted"> completed.</small>
                      </h2>
                  </div>
                 
                  <div class="col-auto">
                    <base-button @click="modals.failedDataImport = false" outline type="danger" size="sm" class="btn-icon-only rounded-circle badge-danger border-0">
                      <span class="btn-inner--icon"><i class="fas fa-times"></i></span>
                    </base-button>
                  </div>
               </div>
               
               <div class="mb-3">
                 <span class="h5 surtitle text-danger">{{failedImportData.length}} failed</span>
                <hr class="my-2">
              </div>
              <ul class="list-group list-group-flush list my--3">
                <li v-for="failedData in failedImportData"
                    :key="failedData.name"
                    class="list-group-item px-0" style="background-color: transparent">
                    <div class="row align-items-center">
                        <div class="col ml--2">
                            <h4 class="mb-0">
                                {{failedData.name}}
                            </h4>
                            <span class="text-danger">● </span>
                            <small>{{failedData.status}}</small>
                            <div><small>{{failedData.msg}}</small></div>
                            
                        </div>
                        
                    </div>
                </li>
              </ul>

              <div slot="footer" class="text-center">
                <base-button type="primary" @click="modals.failedDataImport = false" style="border-radius: 40px">Ok</base-button>
              </div>   
        </modal>
      </div>
    </div></div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from '@/components';
import clientPaginationMixin from './Tables/PaginatedTables/clientPaginationMixin';
import swal from 'sweetalert2';

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      fileUrl: '',
      file:'',
       //csv export
      labels: {
        first_name: 'First Name',
        last_name: 'Last Name',
        staff_phone: 'Phone number',
        staff_email: 'Email',
        code: 'Staff Code',
        name: 'Branch'
      },
      fields : ['first_name', 'last_name', 'staff_phone', 'staff_email', 'code', 'name'],
      propsToSearch: ['first_name','last_name'],
      company: JSON.parse(localStorage.getItem('company')),
      sessionData: JSON.parse(localStorage.getItem('session')),
      value: '',
      loading: false,
      expanded: false,
      branchInvalid: false,
      fileNotSelected: false,
      failedImportData: '',
      branches: [],
      search: '',
      quickEditClasses: {
        inputText:'d-block h3'
      },
      model: {
        firstname: '',
        lastname: '',
        email: '',
        branch: '',
        phonenumber: ''
      },
      tableData: [],
      selectedRows: [],
      modals: {
        staffModal: false,
        staffDataUpload: false,
        failedDataImport: false
      },
    };
  },
  methods: {

    closeImportModal(){
      this.file = '';
      this.branchInvalid = false;
      this.fileNotSelected = false;
      this.modals.staffDataUpload = false;
    },

    handleClick() {
      if (!this.disabled) {
        this.$refs.upload.value = null;
        this.$refs.upload.click();
      }
    },

    fileChange(file){
      //  const url = ;
      // console.log(file.target.files[0])

      this.fileUrl = URL.createObjectURL(file.target.files[0]);
      this.file = file.target.files[0];
      // console.log(this.file.name);
    },

    onImport: async function(){
      if (this.model.branch == '') {
        this.branchInvalid = true;
      }
      
      if (this.file == '') {
        this.fileNotSelected = true;
      }
      
      if (this.model.branch != '' && this.file != '') {
        const loading = this.$loading({
          lock: true,
          text: 'Importing data...',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });

        var formData = new FormData();
        formData.append('file', this.file);

        var uploadCSVFileRes = await this.$http.post(
          this.$baseUrl+"blobs:save&key=file&token="+this.sessionData.token+"&user_id="+this.sessionData.info.id,
          formData,{
            headers: {
              "Content-Type": "multipart/form-data"
            }}).then(res => {
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });

        if (JSON.stringify(uploadCSVFileRes).includes("id")){
          var bulkuploadData = {
            "file": uploadCSVFileRes.id,
            "branch" : this.model.branch,
          }

          var bulkuploadDataRes = await this.$http.post(this.$baseUrl+"StaffCsv:bulkupload&token="+this.sessionData.token,bulkuploadData).then(res => {
            return res;
          }).catch(err=>{

            loading.close();
            return err;
            // console.log(err);
          });
          // console.log(bulkuploadDataRes.data)

          if (bulkuploadDataRes.data.message == 'Staff upload completed') {
            await this.fetchStaff();
            loading.close();
            this.file = '';
            this.model.branch = '';
            this.modals.staffDataUpload = false;

            if (JSON.stringify(bulkuploadDataRes.data.data).includes("Failed")){
              this.failedImportData = bulkuploadDataRes.data.data;
              this.modals.failedDataImport = true;

            }else{
              await this.fetchStaff();
            }
            
          }

          if (JSON.stringify(bulkuploadDataRes).includes("ERR")) {
              //show error notification
                loading.close();

                this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
                  position: "top-right",
                  // timeout: 5000,
                  closeOnClick: true,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false
                });
                
              }
        }

      }
     
    },
    ///get branches data
    fetchCompanyBranches: async function(){
        this.loading = true;
        var resData = await this.$http.get(this.$baseUrl+"branches:list&status=active&token="+this.sessionData.token+"&company_id="+this.company.info.id).then(res =>{
          return res.data;
        }).catch(err=>{
          return err;
        });
      
      if (JSON.stringify(resData).includes("id")) {
        this.branches = resData;
        this.loading = false;
      }
      
      if (JSON.stringify(resData).includes("ERR")) {
        //show error notification
        this.$toast.error("Oops sorry, an error occured", {
          position: "top-right",
          // timeout: 5000,
          closeOnClick: true,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
      }
    },
    veiwDeatail(row) {
      this.$refs.staffTable.toggleRowExpansion(row);
    },
    ///save staff data
    onSubmit: async function(){
      if (this.model.branch == '') {
        this.branchInvalid = true;
      }else{
        const loading = this.$loading({
          lock: true,
          // text: 'Loading',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });

        var phone_number;
        if (this.model.phonenumber.startsWith(0)) {
          phone_number = "233"+this.model.phonenumber.substring(1);
        } else {
          phone_number = "233"+this.model.phonenumber;
        }

        var staffData = {
          "first_name": this.model.firstname,
          "last_name": this.model.lastname,
          "email": this.model.email,
          "phone_number": phone_number,
          // "phone_number": this.model.phonenumber,
          "branch_id": this.model.branch,
        }

        // console.log(staffData);
        var staffRes = await this.$http.post(this.$baseUrl+"Staff:create&token="+this.sessionData.token,staffData).then(res => {
          // console.log(res);
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });

        // console.log(staffRes);

        if (JSON.stringify(staffRes).includes("id")) {
          this.modals.staffModal = false;
          await this.fetchStaff();
          loading.close();

          
          // Resetting Values
          this.model.firstname = this.model.lastname = this.model.email = this.model.branch = this.model.phonenumber =  '';

          // Wait until the models are updated in the UI
          this.$nextTick(() => {
            this.$refs.formValidator.reset();
          });


          this.$toast.success(staffRes.message, {
            position: "top-right",
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        }

        if (staffRes.res == 500) {
          loading.close()

          this.$toast.error(staffRes.msg, {
            position: "top-right",
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }

        if (JSON.stringify(staffRes).includes('ERR')) {
          loading.close()

          this.$toast.error(staffRes['ERR'], {
            position: "top-right",
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      }
      
    },
    //get all staff
    fetchStaff: async function(){
      const loading = this.$loading({
        lock: true,
        // text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgb(255, 255, 255, 0.7)'
      });

      var resData = await this.$http.get(this.$baseUrl+"vw_staff:list&status=active&token="+this.sessionData.token+"&company_id="+this.company.info.id).then(res =>{
        // console.log(res.data);
        return res.data;
      }).catch(err=>{
        return err;
      });

      //Data
      if (resData != "") {
        this.tableData = resData;
        // console.log(this.tableData)
      
        loading.close();
      }

      if (resData == "") {
        loading.close();
      }

      //Error
      if (resData != "" && JSON.stringify(resData).includes("ERR")) {
          loading.close();
          //show error notification
          this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      // console.log(resData);
    },
    onUpdateEmployeeDetail: async function(userId, data, type){

      const loading = this.$loading({
        lock: true,
        text: 'Updating...',
        // spinner: 'el-icon-loading',
        background: 'rgb(255, 255, 255, 0.7)'
      });
    
      var updateData = {
        "id": userId,
        [type] : data,
      }

      // if(type == 'email'){
      //   var updateData = {
      //     "id": userId,
      //     [type] : data,
      //     // "username": data
      //   }
      // }
      // console.log(updateData)

      var updateDataRes = await this.$http.post(this.$baseUrl+"users:save&token="+this.sessionData.token,updateData).then(res => {
        // console.log(res);
        return res.data;
      }).catch(err=>{
        return err;
        // console.log(err);
      });

      if (JSON.stringify(updateDataRes).includes("id")) {
        // await this.fetchCompanyBranches();
        loading.close();
      }
      // console.log(updateDataRes);
      if (JSON.stringify(updateDataRes).includes("ERR")) {
        // console.log(resData)
        //show error notification
        loading.close();
        this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
          position: "top-right",
          // timeout: 5000,
          closeOnClick: true,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
      }

    },
    updateUserBranch: async function(userId, branchId){
      const loading = this.$loading({
        lock: true,
        text: 'Updating...',
        // spinner: 'el-icon-loading',
        background: 'rgb(255, 255, 255, 0.7)'
      });

      var staffId = await this.$http.get(this.$baseUrl+"staff:list&status=active&token="+this.sessionData.token+"&user_id="+userId).then(res => {
        return res.data;
      }).catch(err=>{
        return err.data;
      });
      
      if(staffId != "" && JSON.stringify(staffId).includes("id")){
        var updateData = {
          "id": staffId[0].id,
          "branch_id" : branchId,
        }
        // console.log(updateData)
        // // console.log(updateData)
        var updateDataRes = await this.$http.post(this.$baseUrl+"staff:save&token="+this.sessionData.token,updateData).then(res => {
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });

        if (JSON.stringify(updateDataRes).includes("id")) {
          // await this.fetchCompanyBranches();
          loading.close();
        }
        // console.log(updateDataRes);
        if (JSON.stringify(updateDataRes).includes("ERR")) {
          // console.log(resData)
          //show error notification
          loading.close();
          this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      }

      if (JSON.stringify(staffId).includes("ERR")) {
        // console.log(resData)
        //show error notification
        loading.close();
        this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
          position: "top-right",
          // timeout: 5000,
          closeOnClick: true,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
      }
      
      if (staffId == "") {
        loading.close();
        this.$toast.error("Update failed. kindly check connection and try again later", {
          position: "top-right",
          // timeout: 5000,
          closeOnClick: true,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
      }
    },
    onDelete: async function(row) {
      var deleteRes = await swal.fire({
        title: `Delete ${row.first_name} ${row.last_name}?`,
        text: `Are you sure you want to delete ${row.first_name} ${row.last_name} from staff?`,
        width: '320px',
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        denyButtonText: 'Cancel',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn base-button btn-danger rounded-pill',
          icon: 'icon icon-shape badge-danger rounded-circle',
          title: 'h5 heading', 
          popup: 'bg-secondary',
          cancelButton: 'btn base-button btn-white rounded-pill',
          actions: 'mt-4'
        },
        icon: 'warning',
        iconHtml: '<i class="fas fa-trash"></i>'
      }).then(function(res){
        return res;
      });

      //if yes delete
      // console.log(row);
      if (deleteRes.isConfirmed) {
        await this.deleteAStaff(row.id);
      }
    },
    deleteAStaff: async function(staffId){
      // console.log(called)
      const loading = this.$loading({
        lock: true,
        background: 'rgb(255, 255, 255, 0.7)'
      });
      
      var staffDelete = {
        "id": staffId,
        "status": "deleted",

      };

      var staffDeleteRes = await this.$http.post(this.$baseUrl+"staff:save&token="+this.sessionData.token,staffDelete).then(res => {
        return res.data;
      }).catch(err=>{
        return err;
        // console.log(err);
      });

      if (JSON.stringify(staffDeleteRes).includes("id")) {
        await this.fetchStaff();
        loading.close();
      }

      if (JSON.stringify(staffDeleteRes).includes("ERR")) {
        //show error notification
        loading.close();
        this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
          position: "top-right",
          // timeout: 5000,
          closeOnClick: true,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
      }
    },
    // generateRandomColor() {
    //   var r = () => Math.random() * 180 >> 0;
    //   return `rgba(${r()}, ${r()}, ${r()})`;
    // }
    
  },
  
  created() {
    this.fetchStaff()
    this.fetchCompanyBranches()
  },
};
</script>
<style>
.tableSelectShadow .el-input.el-input--suffix{
  border: none !important;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
  .no-border-card .card-footer{
    border-top: 0;
  }
 
</style>
