<template>
  <div>
    <!-- Page content -->
    <div class="container mt--8 pb-5 mt-6">
      <!-- Table -->
      <div class="row justify-content-center bubble_bg">
        <div class="col-lg-7 col-md-9">
          <!-- <div class="text-center mb-5">
            <img src="img/brand/klok_logo.png" alt="Klok logo" width="250" class="img-fluid">
          </div> -->
          <div class="card shadow-none bg-transparent">
            <div class="card-body px-5 py-4">
              <div class="text-right mb-1">
                <img src="img/brand/logo.png" alt="Kazi logo" width="80" class="img-fluid">
              </div>
              <div class="text-left text-muted mb-4">
                <h2>
                  Create 
                  <small class="text-muted"> your Account</small>
                </h2>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <div class="row">

                    <div class="col-lg-6 pr-lg-2">
                      <base-input alternative
                                class="mb-3"
                                prepend-icon="ni ni ni-circle-08"
                                placeholder="First name"
                                name="first name"
                                :rules="{required: true}"
                                v-model="model.firstname">
                      </base-input>
                    </div>

                    <div class="col-lg-6 pl-lg-2">
                      <base-input alternative
                                class="mb-3"
                                prepend-icon="ni ni ni-circle-08"
                                placeholder="Last name"
                                name="last name"
                                :rules="{required: true}"
                                v-model="model.lastname">
                      </base-input>
                    </div>

                  </div>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              name="email"
                              :rules="{required: true}"
                              v-model="model.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-briefcase-24"
                              placeholder="Comapny name"
                              name="company"
                              :rules="{required: true}"
                              v-model="model.company">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-square-pin"
                              placeholder="Location/Branch"
                              name="branch"
                              :rules="{required: true}"
                              v-model="model.branch">
                  </base-input>

                  <!-- <base-input alternative
                              class="mb-3"
                              prepend-icon="fa fa-map-marked-alt"
                              placeholder="Address"
                              name="Address"
                              :rules="{required: true}"
                              v-model="model.address">
                  </base-input> -->

                  <base-input alternative
                              type="tel"
                              class="mb-3"
                              prepend-icon="fa fa-phone"
                              placeholder="Phone number"
                              name="phone number"
                              :rules="{required: true}"
                              v-model="model.phonenumber">
                  </base-input>
                  <div class="row">
                    <div class="col-lg-6 pr-lg-2">
                      <base-input alternative
                              prepend-icon="ni ni-lock-circle-open"
                              placeholder="Password"
                              type="password"
                              name="password"
                              :rules="{required: true, min: 8,  regex: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/}"
                              v-model="model.password">
                      </base-input>
                    </div>

                    <div class="col-lg-6 pl-lg-2">
                      <base-input alternative
                              prepend-icon="ni ni-lock-circle-open"
                              placeholder="Confirm Password"
                              type="password"
                              name="confirm password"
                              :rules="{required: true, confirmed: 'password',}"
                              v-model="model.confirmpassword">
                      </base-input>
                    </div>
                  </div>
                  
                  <div class="text-muted mb-2 font-italic">
                    <small>
                      Password must be at least 8 characters with a mix of letters, numbers and symbols.
                    </small>
                  </div>

                  <div class="text-muted font-italic">
                    <small>
                      By clicking "Create account" you agree to our 
                      <a href="https://kaziapps.com/terms-of-service/" class="text-primary" target="_blank">Terms of Service</a>, 
                      <a href="https://kaziapps.com/privacy-policy/" class="text-primary" target="_blank">Privacy Policy</a>, 
                      and to receive communications from Kazi apps.
                    </small>
                  </div>
                  <div class="text-right">
                    <button type="submit" class="btn btn-primary mt-4 rounded_btn new_primary_bg">Create account</button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'register',
    data() {
      return {
        model: {
          firstname: '',
          lastname: '',
          email: '',
          company:'',
          branch: 'HQ',
          // address: '',
          phonenumber: '',
          password: '',
          confirmpassword: '',
          agree: false
        }
      }
    },
    methods: {
      async onSubmit() {

        const loading = this.$loading({
          lock: true,
          // text: 'Loading',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });

        var newAccountData = {
          "first_name": this.model.firstname,
          "last_name": this.model.lastname,
          "email": this.model.email,
          "company": this.model.company,
          "branch_name": this.model.branch,
          "phone_number": this.model.phonenumber,
          "password": this.model.password,
        }
        
        
        await this.$http.post(this.$baseUrl+"Client:create&token="+this.$publicToken,newAccountData).then(res => {
          var resData = res.data;
          // console.log(resData);
          

          if (resData.res == 200) {
            this.$toast.success(resData.message, {
              position: "top-right",
              closeOnClick: true,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            //
            this.$router.push({path: '/login'});
            loading.close()
          }

          // //
          if (resData.res == 500) {
            loading.close()

            this.$toast.error(resData.message, {
              position: "top-right",
              closeOnClick: true,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
          }
        }).catch(err=>{
          // console.log(err);
        });
      }
    }

  };
</script>
<style>
.new_primary_bg{
   background-color: #0023FF !important;
 }
 .bubble_bg{
   background-image: url('../../../public/img/bubble.png');
   background-position: 50px 50px;
   background-repeat: no-repeat;
 }
 .rounded_btn{
   border-radius: 10rem !important;
 }
</style>