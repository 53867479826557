<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Branches</h6>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral" @click="modals.branchModal = true">Add new branch</base-button>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6 mb-9">
      <!--Lists-->
      <div class="row" >
        <div class="col-lg-4 col-md-4 col-sm-12" v-for="(branch, index) in branches" :key="index">
          <div class="card">
                <!-- Card body -->
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <span class="h6 surtitle text-muted">Branch Code</span>
                            <span class="d-block h3">{{branch.branch_code}}</span>
                        </div>
                        <div class="col-auto">
                          <el-tooltip content="Delete" placement="top">
                            <base-button outline size="sm" type="danger"  class="btn-icon-only rounded-circle badge-danger" @click="onDelete(branch)">
                                <span class="btn-inner--icon"><i class="fas fa-trash"></i></span>
                            </base-button>
                          </el-tooltip>
                           
                          <badge rounded type="primary" class="badge-success ml-3">
                            {{branch.status}}
                          </badge>
                        </div>
                    </div>
                    <div class="my-2 row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Name</span>
                        <!-- <div class="h1">{{branch.name}}</div> -->
                        <quick-edit v-model="branch.name" @input="updateBranchData(branch.id, branch.name, 'name')"></quick-edit>
                        <!-- <base-button size="sm" type="neutral" @click="editIt = true">edit</base-button> -->
                      </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="h6 surtitle text-muted">Location</span>
                            <span class="d-block h3">Lat:  {{branch.latitude}}, Lng:  {{branch.longitude}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="h6 surtitle text-muted">Address</span>
                            <quick-edit v-model="branch.address" @input="updateBranchData(branch.id, branch.address, 'address')" :classes="quickEditClasses"></quick-edit>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="h6 surtitle text-muted">Phone number</span>
                            <quick-edit v-model="branch.phone_number" @input="updateBranchData(branch.id, branch.phone_number, 'phone_number')" :classes="quickEditClasses"></quick-edit>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="h6 surtitle text-muted">Email</span>
                            <quick-edit v-model="branch.email" @input="updateBranchData(branch.id, branch.email, 'email')" :classes="quickEditClasses"></quick-edit>
                        </div>
                    </div>
                    <hr class="my-2">
                    <small class="text-muted font-italic">Capture staff/employee photos during sign-in for increased security & identification</small>
                    <div class="row align-items-center mt-2">
                      <div class="col-auto display-3">
                          <i class="fas fa-portrait"></i>
                      </div>
                      <div class="col ml--3">
                          <h4 class="mb-0">Staff Photos</h4>
                          <small class="text-muted font-italic">Capture photos</small>
                      </div>
                      <div class="col-4">
                        <el-select v-model="branch.attendance_image" @change="updatePhotoSettings(branch.attendance_image,branch.id)">
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <!-- update button -->
                    <!-- <div class="mt-3">
                      <base-button block outline class="badge-primary border-0" style="border-radius: 40px">Save changes</base-button>
                    </div> -->
                </div>
            </div>
        </div>
       
      </div>
      <!--End lists-->
    </div>
    <div>
      <modal :show.sync="modals.branchModal"
               body-classes="px-5 pb-5"
               header-classes="pt-4 px-lg-5 pb-0"
               gradient="secondary"
               modal-classes="modal-dialog-centered">

               <!-- <h6 slot="header" class="modal-title">Branch</h6> -->
               <div class="row mb-4">
                  <div class="col text-muted">
                      <!-- <span class="h6 text-muted">Branch</span> -->
                      <h2>
                        Add
                        <small class="text-muted"> a new branch</small>
                      </h2>
                  </div>
                 
                  <div class="col-auto">
                    <base-button @click="modals.branchModal = false" outline type="danger" size="sm" class="btn-icon-only rounded-circle badge-danger border-0">
                      <span class="btn-inner--icon"><i class="fas fa-times"></i></span>
                    </base-button>
                  </div>
               </div>
              
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                          class="mb-3"
                          prepend-icon="ni ni-square-pin"
                          placeholder="Location/Branch name"
                          name="Branch"
                          :rules="{required: true}"
                          v-model="model.branch">
                  </base-input>

                  <base-input alternative
                        type="tel"
                        class="mb-3"
                        prepend-icon="fas fa-phone"
                        placeholder="Phone number"
                        name="Phone number"
                        v-model="model.phonenumber">
                  </base-input>

                  <base-input alternative
                          class="mb-3"
                          prepend-icon="ni ni-email-83"
                          placeholder="Email"
                          name="Email"
                          :rules="{email: true}"
                          v-model="model.email">
                  </base-input>

                  <base-input alternative
                          class="mb-3"
                          prepend-icon="fa fa-map-marked-alt"
                          placeholder="Address"
                          name="Address"
                          v-model="model.address">
                  </base-input>
                  <div slot="footer" class="text-right">
                    <base-button type="primary" nativeType="submit" style="border-radius: 40px">Save</base-button>
                  </div>
                </form>
            </validation-observer>
        </modal>
      </div>
  </div>
</template>
<script>

  // Components
  import BaseHeader from '@/components/BaseHeader';
  import {Select, Option} from 'element-ui';
  import swal from 'sweetalert2';

  export default {
    components: {
      BaseHeader,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
         company: JSON.parse(localStorage.getItem('company')),
         sessionData: JSON.parse(localStorage.getItem('session')),
         branches: [],
          quickEditClasses: {
            inputText:'d-block h3'
          },
         modals:{
          branchModal: false,
          deleteModal: false
         },
         model: {
          email: '',
          branch: '',
          address: '',
          phonenumber: '',
         },
        editable: true,
        options: [{
          value: '0',
          label: 'No'
        }, {
          value: '1',
          label: 'Yes'
        }]
      };
    },
    // methods
    methods:{
      fetchCompanyBranches: async function(){
        const loading = this.$loading({
          lock: true,
          // text: 'Loading',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });
        // console.log(this.company)
         var resData = await this.$http.get(this.$baseUrl+"branches:list&status=active&token="+this.sessionData.token+"&company_id="+this.company.info.id).then(res =>{
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });
        // console.log(resData)
        if (JSON.stringify(resData).includes("id")) {
          // console.log(this.branches)
          this.branches = resData;
          loading.close()
          // console.log(this.branches)
        }
        
        if (JSON.stringify(resData).includes("ERR")) {
          //show error notification
          this.$toast.error("Oops sorry, an error occured", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      },
      //Add new branch
      onSubmit: async function(){
        const loading = this.$loading({
          lock: true,
          // text: 'Loading',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });
        // console.log(this.company.info.client_id)

        var newBranchData = {
          "company": this.company.info.id,
          "name": this.model.branch,
          "phone_number": this.model.phonenumber,
          "email": this.model.email,
          "address": this.model.address
        };

        // console.log(newBranchData);

        var branchSaveRes = await this.$http.post(this.$baseUrl+"Branch:create&token="+this.sessionData.token,newBranchData).then(res => {
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });

        // console.log(branchSaveRes);

        if (JSON.stringify(branchSaveRes).includes("id")) {
          this.modals.branchModal = false;
          await this.fetchCompanyBranches();
          loading.close();
        }

        if (JSON.stringify(branchSaveRes).includes("ERR")) {
          // console.log(resData)
          this.modals.branchModal = false;
          loading.close();
          //show error notification
          this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }

      },
      //update staff photo capture
      updatePhotoSettings: async function (selected,branchId) {
        const loading = this.$loading({
          lock: true,
          text: 'Updating setting',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });
       
        var photoSettings = {
          "id": branchId,
          "attendance_image": selected,

        };

        var updatePhotoSettingsRes = await this.$http.post(this.$baseUrl+"branches:save&token="+this.sessionData.token,photoSettings).then(res => {
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });

        if (JSON.stringify(updatePhotoSettingsRes).includes("id")) {
          // await this.fetchCompanyBranches();
          loading.close();
        }

        if (JSON.stringify(updatePhotoSettingsRes).includes("ERR")) {
          // console.log(resData)
          //show error notification
          loading.close();
          this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      },
      //delete branch
      onDelete: async function(branch) {
        var deleteRes = await swal.fire({
          title: `Delete ${branch.name}?`,
          text: `Are you sure you want to delete ${branch.name} branch from branches?`,
          width: '320px',
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: 'Yes, Delete',
          denyButtonText: 'Cancel',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn base-button btn-danger rounded-pill',
            icon: 'icon icon-shape badge-danger rounded-circle',
            title: 'h5 heading', 
            popup: 'bg-secondary',
            cancelButton: 'btn base-button btn-white rounded-pill',
            actions: 'mt-4'
          },
          icon: 'warning',
          iconHtml: '<i class="fas fa-trash"></i>'
        }).then(function(res){
          return res;
        });
        //if yes delete
        if (deleteRes.isConfirmed) {
          await this.deleteAbranch(branch.id);
        }
      },
      deleteAbranch: async function(branchId){
        // console.log(branchId)
        const loading = this.$loading({
          lock: true,
          text: 'Deleting branch',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });
       
        var photoSettings = {
          "id": branchId,
          "status": "deleted",

        };

        var updatePhotoSettingsRes = await this.$http.post(this.$baseUrl+"branches:save&token="+this.sessionData.token,photoSettings).then(res => {
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });

        if (JSON.stringify(updatePhotoSettingsRes).includes("id")) {
          await this.fetchCompanyBranches();
          loading.close();
        }

        if (JSON.stringify(updatePhotoSettingsRes).includes("ERR")) {
          // console.log(resData)
          //show error notification
          loading.close();
          this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      },
      updateBranchData: async function(id, name, dataType){
        // console.log(id)
        // console.log(name)
        const loading = this.$loading({
          lock: true,
          text: 'Updating...',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });

        var updateData = {
          "id": id,
          [dataType] : name,
        };
        
        // console.log(updateData)
        var updateDataRes = await this.$http.post(this.$baseUrl+"branches:save&token="+this.sessionData.token,updateData).then(res => {
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });

        if (JSON.stringify(updateDataRes).includes("id")) {
          // await this.fetchCompanyBranches();
          loading.close();
        }
        // console.log(updateDataRes);
        if (JSON.stringify(updateDataRes).includes("ERR")) {
          // console.log(resData)
          //show error notification
          loading.close();
          this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      },
      cancelEdit(closed){
        // console.log(closed)
      }
    },
    created() {
      this.fetchCompanyBranches()
      // console.log(this.value)
    },
  };
</script>
<style></style>
