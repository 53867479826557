import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';


// Pages
// Dashboard pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/WelcomeDashboard.vue');
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const ForgotPassword = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/ForgotPassword.vue');
const ResetPassword = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/ResetPassword.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
// const CompanyDetails = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/CompanyDetails.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');
const AttendanceLog = () => import(/* webpackChunkName: "pages" */ '../views/Pages/AttendaceLog.vue');
const Staff = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Staff.vue');
const Branches = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Branches.vue');
const Settings = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Settings.vue');
const Billing = () => import(/* webpackChunkName: "pages" */ '../views/Pages/Billing.vue');


const routes = [
  {
    path: '/',
    redirect: '/login',
    name: 'Login'
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: 'attendance-log',
        name: 'Attendance-log',
        component: AttendanceLog,
        // meta: {
        //   navbarType: 'light'
        // }
      },
      {
        path: 'staff',
        name: 'Staff',
        component: Staff,
        // meta: {
        //   navbarType: 'light'
        // }
      },
      {
        path: 'branches',
        name: 'Branches',
        component: Branches
      },
      {
        path: 'account',
        name: 'Account',
        component: User
      },
      {
        path: 'billing',
        name: 'Billing',
        component: Billing,
        // meta: {
        //   navbarType: 'light'
        // }
      },
    ]
  },
  {
    path: '/',
    component: AuthLayout,
    // redirect: '/home',
    name: 'Authentication',
    children: [
      {
        path: '/login',
        // name: 'Login',
        component: Login,
        meta: {
          noBodyBackground: true
        }
      },
      {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
          noBodyBackground: true
        }
      },
      {
        path: '/forgot_password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
          noBodyBackground: true
        }
      },
      {
        path: '/reset_password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          noBodyBackground: true
        }
      },
      {
        path: '/lock',
        name: 'Lock',
        component: Lock,
        meta: {
          noBodyBackground: true
        }
      },
      // { path: '*', component: NotFound }
    ]
  },
  // welcomePages,
];

export default routes;
