<template>
  <div ref="el" :class="classNames.wrapper">
    <template v-if="isEditing && isEnabled">
      <div class="row">
        <div class="col">
            <base-input :class="classNames.input"
                :type="type"
                v-model="inputValue"
                v-bind="$attrs"
                :tabindex="tabIndex"
                @focusin="handleFocus"
                @focusout="handleFocus"
                @keypress.enter="ok"
                @keypress.escape.exact="close">
            </base-input>
        </div>

      <div v-if="showButtons" :class="classNames.buttons">
        <base-button 
          @click="ok"
          @focusin="handleFocus"
          @focusout="handleFocus"
          :class="classNames.buttonOk"
          :title="buttonOkText"
          outline 
          type="success" 
          size="sm" 
          class="btn-icon-only rounded-circle badge-success border-0">
            <span class="btn-inner--icon"><i class="ni ni-check-bold"></i></span>
        </base-button>

        <!-- <base-button @click="close"
          @focusin="handleFocus"
          @focusout="handleFocus" 
          outline 
          type="danger" 
          size="sm" 
          class="btn-icon-only rounded-circle badge-danger border-0">
            <span class="btn-inner--icon"><i class="fas fa-times"></i></span>
        </base-button> -->
        <button
          @click="close"
          @focusin="handleFocus"
          @focusout="handleFocus"
          type="button"
          class="btn-icon-only rounded-circle badge-danger border-0 btn base-button btn-outline-danger btn-sm"
        >
        <span class="btn-inner--icon"><i class="fas fa-times"></i></span>
          <!-- <slot name="button-cancel">{{ buttonCancelText }}</slot> -->
        </button>
      </div>
      </div>
      
    </template>
    <template v-else>
      <slot name="prepend"></slot>
      <span
        :class="{
          [classNames.link]: true,
          [classNames.isClickable]: isEnabled,
          [classNames.isEmpty]: isEmpty,
          [classNames.isRequired]: isRequired && isEmpty,
        }"
        :tabindex="isEnabled ? tabIndex : false"
        
        >
        <div class="row">
           <div class="col"><slot :value="displayValue" :raw-value="theValue"><div :class="classNames.inputText">{{ displayValue }}</div></slot></div>
           
           <div class="col-auto">
               <el-tooltip content="Edit" placement="top">
                <base-button outline size="sm" type="primary"  @click="handleClick" class="btn-icon-only rounded-circle badge-primary border-0">
                    <span class="btn-inner--icon"><i class="el-icon-edit"></i></span>
                </base-button>
               </el-tooltip>
           </div>
        </div>
        
      </span>
      <slot name="append"></slot>
    </template>
  </div>
</template>

<script>
const mune = keys =>
  keys.reduce((acc, cur) => {
    acc[cur] = cur;
    return acc;
  }, {});
const states = mune(['display', 'edit']);
const events = mune(['input', 'rawInput', 'show', 'close', 'invalid', 'focusin']);
const types = mune([
  'boolean',
  'input',
  'textarea',
]);
const modes = mune(['ok', 'cancel', 'ignore']);

export default {
  name: 'QuickEdit',
  props: {
    buttonOkText: {
      type: String,
      default: 'Ok',
    },
    buttonCancelText: {
      type: String,
      default: 'Cancel',
    },
    emptyText: {
      type: String,
      default: 'Empty',
    },
    booleanYesText: {
      type: String,
      default: 'Yes',
    },
    booleanNoText: {
      type: String,
      default: 'No',
    },
    type: {
      type: String,
      default: types.input,
    },
    options: {
      type: Array,
      default: () => [],
      },
    mode: {
      type: String,
      default: modes.ok,
      validator: function(value) {
        return !!modes[value];
      },
    },
    value: {
      type: [String, Array, Boolean, Number],
      default: '',
    },
    placeholderValue: {
      type: String,
      default: '',
    },
    classes: {
      type: Object,
      default: () => null,
      },
    validator: {
      type: Function,
      default: null,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
    formatMultiple: {
      type: Function,
      default: values => values.join(', '),
  },
  },
  computed: {
    isEmpty() {
      return '' === this.prettyValue || null === this.prettyValue;
    },
    isEditing() {
      return states.edit === this.inputState;
    },
    isEnabled() {
      return !this.$attrs.disabled && this.$attrs.disabled !== '';
    },
    isRequired() {
      return this.$attrs.required || '' === this.$attrs.required;
    },
    isMultiple() {
      return (
        this.displayOptions.length &&
        (this.types.select === this.type ||
          this.types.checkbox === this.type ||
          this.types.radio === this.type)
      );
    },
    prettyValue() {
      return this.isMultiple
        ? Array.isArray(this.theValue)
          ? this.formatMultiple(this.theValue.map(this.getDisplayOption))
          : this.getDisplayOption(this.theValue)
        : this.theValue;
    },
    displayOptions() {
      const [firstEl] = this.options;
      return firstEl && typeof firstEl === 'string'
        ? this.options.map(x => ({ value: x, text: x }))
        : this.options;
    },
    displayValue() {
      if (this.types.boolean === this.type)
        return this.theValue ? this.booleanYesText : this.booleanNoText;
      else if (this.types.password === this.type) return '•'.repeat(8);
      return this.isEmpty ? this.emptyText : this.prettyValue;
    },
    classNames() {
      return Object.assign({}, this.defaultClasses, this.classes);
    },
    tabIndex() {
      return this.$attrs.tabindex || 0;
    },
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
  },
  data() {
    return {
      inputState: this.startOpen ? states.edit : states.display,
      theValue: '',
      inputValue: '',
      types,
      defaultClasses: {
        buttonCancel: 'vue-quick-edit__button vue-quick-edit__button--cancel',
        inputText:'h1',
        buttonOk: 'vue-quick-edit__button vue-quick-edit__button--ok',
        buttons: 'vue-quick-edit__buttons',
        input: 'vue-quick-edit__input',
        link: 'vue-quick-edit__link',
        isClickable: 'vue-quick-edit__link--is-clickable',
        isEmpty: 'vue-quick-edit__link--is-empty',
        isRequired: 'vue-quick-edit__link--is-required',
        wrapper: 'vue-quick-edit',
      },
    };
  },
  methods: {
    handleClick() {
      if (!this.isEnabled) return;

      if (this.types.boolean === this.type) {
        this.theValue = !this.theValue;
        this.$emit(events.input, this.theValue);
      } else {
        this.show();
      }
    },
    handleFocus({ type }) {
      if (events.focusin === type) {
        clearTimeout(this._handleFocus);
      } else {
        this._handleFocus = setTimeout(this.clickOutside, 0);
      }
    },
    show(doFocus = true) {
      this.inputValue = this.theValue;
      this.inputState = states.edit;
      this.$emit(events.show, this.theValue);
      doFocus && this.focus();
    },
    close(doFocus = true) {
      this.inputState = states.display;
      this.$emit(events.close, this.theValue);
      doFocus && this.focus();
    },
    ok(doFocus = true) {
      if (this.validator) {
        const error = this.validator(this.inputValue);
        if (error) {
          this.$emit(events.invalid, this.theValue, error);
          return;
        }
      }
      this.theValue = this.inputValue;
      this.$emit(events.input, this.theValue);
      this.$emit(events.rawInput, this.inputValue);
      this.close(doFocus);
    },
    focus() {
      setTimeout(() => {
        const className = this.isEditing ? 'input,select,textarea' : 'span';
        const el = this.$refs.el && this.$refs.el.querySelector(className);
        el && el.focus();
      }, 0);
    },
    setValue(value) {
      this.theValue = value;
      this.inputValue = value;
    },
    clickOutside() {
      if (this.inputState !== states.edit) return;
      if (modes.ok === this.mode) this.ok(false);
      else if (modes.cancel === this.mode) this.close(false);
    },
    getDisplayOption(opt) {
      const option = this.displayOptions.find(x => x.value === opt);
      return option ? option.text : '';
    },
  },
  created() {
    this.setValue(this.value);
  },
};
</script>

<style lang="scss">
$link-color: #0088cc;
$link-hover-color: #2a6496;
$primary-color: #3276b1;
$primary-text-color: #fff;
$primary-border-color: #357ebd;
$danger-color: #dc3545;
$default-color: #fff;
$default-text-color: #333;
$border-color: #ccc;
$quick-edit-height: 32px;

.vue-quick-edit {
  &__link {
    white-space: pre-wrap;
    // color: $link-color;
    outline: none;

    &--is-clickable {
      border-bottom: 1px dashed $link-color;
    //   cursor: pointer;
      outline: none;
    }

    &--is-empty {
      font-style: italic;
      color: gray;
    }

    &--is-required {
      color: $danger-color;
    }
  }

  &__input {
    background-color: #f9f9f9;
    color: #333;
    // border: 1px solid $border-color;
    height: $quick-edit-height;
    padding: 0;
  }

  &__buttons {
    margin-top: 8px;
  }

  &__button {
    height: $quick-edit-height + 2px;
    min-width: $quick-edit-height + 2px;
    // border: 1px solid $border-color;

    &--ok {
      color: $primary-text-color;
      background-color: $primary-color;
    //   border-color: $primary-border-color;
    }

    &--cancel {
      color: $default-text-color;
      margin-left: 8px;
      background-color: $default-color;
    }
  }
}

[multiple].vue-quick-edit__input,
textarea.vue-quick-edit__input {
  height: unset;
//   min-height: $quick-edit-height * 2;
  display: block;
}

.vue-quick-edit__input:not(textarea):not([multiple]) + .vue-quick-edit__buttons,
label + .vue-quick-edit__buttons {
  display: inline;
  margin-left: 8px;
}
</style>