<template>
  <div>
    <!-- Page content -->
    <div class="container mt--5 pb-5 mt-lg-5 py-7">
      <div class="row justify-content-center bubble_bg">
        <div class="col-lg-5 col-md-7">
          <!-- <div class="text-center mb-5">
            <img src="img/brand/klok_logo.png" alt="Klok logo" width="250" class="img-fluid">
          </div> -->
          <div class="card mb-0 mt-5 shadow-none bg-transparent">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-right text-muted mb-4">
                <h2>
                  <div><span>Login |</span> <img src="img/brand/logo.png" alt="Klok logo" width="80" class="img-fluid"></div>
                 
                </h2>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="model.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <!-- <base-checkbox v-model="model.rememberMe">Remember me</base-checkbox> -->
                  <div class="text-right">
                    <base-button type="primary" native-type="submit" class="my-4 new_primary_bg rounded_btn">Sign in</base-button>
                  </div>
                </form>
              </validation-observer>

              <div class="row mt-3">
                <div class="col-lg-6">
                  <router-link to="/forgot_password"><small><b>Forgot password?</b></small></router-link>
                </div>
                <div class="col-lg-6 text-right">
                  <router-link to="/register"><small><b>Create new account</b></small></router-link>
                </div>
              </div>

            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        model: {
          // email: 'cniinai@gmail.com',
          // email: 'frankasante@mail.com',
          // password: '123456'
          email: '',
          password: ''
        },
        fullscreenLoading: false
      };
    },
    methods: {
      async onSubmit() {
         
        var loginData = {
          "username": this.model.email,
          "password": this.model.password
        };
        // this will be called only after form is valid.
        const loading = this.$loading({
          lock: true,
          // text: 'Loading',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });
        var userSession = await this.$http.post(this.$baseUrl+"sessions:login&token="+this.$publicToken,loginData).then(res =>{
          // console.log(res);
          return res.data;
        }).catch(err=>{
          return err
          // console.log(err);
        });

        //
        if (JSON.stringify(userSession).includes("token")) {
          localStorage.setItem('session', JSON.stringify({
            token: userSession.token,
            info: userSession.info[0]
          }));

          await this.fetchComapnyData();
          // this.fetchComapnyData("resData['token']","resData['info'][0][id]");
          this.$router.push({name: 'Dashboard'});
          loading.close();
        }
        
        if (JSON.stringify(userSession).includes("ERR")) {
          loading.close();
          //show error notification
          this.$toast.error(userSession['ERR'], {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      },
      //fetch clients data
      async fetchClientData(){
        var sessionData = JSON.parse(localStorage.getItem('session'));
        return await this.$http.get(this.$baseUrl+"user_clients:list&status=active&token="+sessionData.token+"&user_id="+sessionData.info.id).then(res =>{
          // console.log(res);
          return res.data;
        }).catch(err=>{
          // console.log(err);
          return err;
        });
      },
      //then use clients id to fetch comapay data
      async fetchComapnyData(){
        var sessionData = JSON.parse(localStorage.getItem('session'));
        var clientData = await this.fetchClientData();
        // console.log(clientData)
         localStorage.setItem('client', JSON.stringify({
            info: clientData[0]
          }));
        // console.log(clientData[0]['client_id'])

        var resData = await this.$http.get(this.$baseUrl+"companies:list&status=active&token="+sessionData.token+"&client_id="+clientData[0]['client_id']).then(res =>{
          // console.log(res);
          return res.data;
        }).catch(err=>{
          // console.log(err);
          return err;
        });
        // console.log(resData)
        if (JSON.stringify(resData).includes("id")) {
          // console.log(resData[0])
          localStorage.setItem('company', JSON.stringify({
            info: resData[0]
          }));
        }
        
        if (JSON.stringify(resData).includes("ERR")) {
          // console.log(resData)
          //show error notification
          this.$toast.error("Oops sorry, an error occured", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
        
      }
    }
  };
</script>
<style>
 .new_primary_bg{
   background-color: #0023FF !important;
 }
 .bubble_bg{
   background-image: url('../../../public/img/bubble.png');
   background-position: 50px 50px;
   background-repeat: no-repeat;
 }
 .rounded_btn{
   border-radius: 10rem !important;
 }
</style>
