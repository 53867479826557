<template>
  <div>
    <!-- Page content -->
    <div class="container mt--5 pb-5 mt-lg-5 py-7">
      <div class="row justify-content-center bubble_bg">
        <div class="col-lg-5 col-md-7">
          <div class="card mb-0 mt-5 shadow-none bg-transparent">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-right text-muted mb-4">
                <h2>
                  <div><span>Forgot Password |</span> <img src="img/brand/logo.png" alt="Klok logo" width="80" class="img-fluid"></div>  
                </h2>
              </div>
              
              <div v-if="!showMessage">
                <div class="text-muted mb-2">
                  <small>
                    Enter your email address below and we will send you a link with instructions to reset your password.
                  </small>
                </div>
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                  <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <base-input alternative
                                class="mb-3"
                                name="Email"
                                :rules="{required: true, email: true}"
                                prepend-icon="ni ni-email-83"
                                placeholder="Email"
                                v-model="model.email">
                    </base-input>
                    <!-- Enter your email address below and we will send you instructions on how to change your password. -->
                    <div class="text-right">
                      <base-button type="primary" native-type="submit" class="my-4 new_primary_bg rounded_btn">Submit</base-button>
                    </div>
                  </form>
                </validation-observer>
              </div>
              <!-- Password reset link sent -->
              <div v-if="showMessage">
                <p class="font-weight-bold">We've sent you an email. Click the link in the email to reset your password. </p>
                <p>If you don't see the email, kindly check other places it might be, like your junk, spam, social, or other folders.</p>
              </div>

              <div class="row mb-5">
                <div class="col text-center">
                  <router-link to="/login"><small><b>« back to LOGIN</b></small></router-link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        model: {
          email: ''
        },
        fullscreenLoading: false,
        showMessage: false
      };
    },
    methods: {
      async onSubmit() {
         
        var userData = {
          "email": this.model.email
        };

        // f=>Forgot:users

        // via post
        // email=>$email
        // // this will be called only after form is valid.
        const loading = this.$loading({
          lock: true,
          // text: 'Loading',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });
        var userForgot = await this.$http.post(this.$baseUrl+"Forgot:users&token="+this.$publicToken,userData).then(res =>{
          // console.log(res);
          return res.data;
        }).catch(err=>{
          return err
          // console.log(err);
        });

        // //
        if (userForgot.MSG == 'Password reset link sent') {
          this.showMessage = true;
          loading.close();
        }
        
        if (JSON.stringify(userForgot).includes("ERR")) {
          loading.close();
          //show error notification
          this.$toast.error('Something went wrong. Kindly check and try again.', {
            position: "top-center",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      },
      
    }
  };
</script>
<style>
 .new_primary_bg{
   background-color: #0023FF !important;
 }
 .bubble_bg{
   background-image: url('../../../public/img/bubble.png');
   background-position: 50px 50px;
   background-repeat: no-repeat;
 }
 .rounded_btn{
   border-radius: 10rem !important;
 }
</style>
