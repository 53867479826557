<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Welcome! {{company != null ? this.company.info.name : ''}}</h6>
        </div>
        <div class="col-lg-6 col-5 text-right">
        </div>
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card bg-gradient-primary border-0">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">Total Employees</h5>
                  <span class="h2 font-weight-bold mb-0 text-white">{{totalStaff}}</span>
                </div>
                <div class="col-auto">
                  <base-button class="btn-icon-only btn-sm rounded-circle btn-neutral" @click="$router.push({name: 'Staff'})">
                      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
                  </base-button>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-nowrap text-white font-weight-600">Entire company</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-gradient-info border-0">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">Branches</h5>
                  <span class="h2 font-weight-bold mb-0 text-white">{{totalBranches}}</span>
                </div>
                <div class="col-auto">
                  <base-button class="btn-icon-only btn-sm rounded-circle btn-neutral" @click="$router.push({name: 'Branches'})">
                      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
                  </base-button>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-nowrap text-white font-weight-600">All branches</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-gradient-success border-0">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">Clocked in</h5>
                  <span class="h2 font-weight-bold mb-0 text-white">{{totalAttendance}}</span>
                </div>
                <div class="col-auto">
                  <base-button class="btn-icon-only btn-sm rounded-circle btn-neutral" @click="$router.push({name: 'Attendance-log'})">
                      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
                  </base-button>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-nowrap text-white font-weight-600">Today's signed in staff</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-gradient-danger border-0">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">Absent</h5>
                  <span class="h2 font-weight-bold mb-0 text-white">{{totalAbsent}}</span>
                </div>
                <div class="col-auto">
                  <base-button class="btn-icon-only btn-sm rounded-circle btn-neutral" @click="$router.push({name: 'Attendance-log'})">
                      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
                  </base-button>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <span class="text-nowrap text-white font-weight-600">Not clocked in today</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-red" icon="fas fa-walking">

            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Total Atendance</h5>
                    <span class="h2 font-weight-bold mb-0">{{this.totalAttendance}}</span>
                </div>
            </div>
          </stats-card>
        </div>

        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-orange" icon="ni ni-badge">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Total Employees</h5>
                    <span class="h2 font-weight-bold mb-0">{{this.totalStaff}}</span>
                </div>
            </div>
          </stats-card>
        </div>

        <div class="col-xl-3 col-md-6">
          <stats-card type="gradient-green"
                      icon="ni ni-shop">
            <div class="row">
                  <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Total Branches</h5>
                      <span class="h2 font-weight-bold mb-0">{{this.totalBranches}}</span>
                  </div>
              </div>
          </stats-card>

        </div>
       
      </div> -->

    </base-header>

    <div class="container-fluid mt--6 mb-9">
      <div class="row card-wrapper">
        <div class="col-lg-6">

          <!-- Basic with card title -->
          <card>
            <!-- Card body -->
            <h3 class="card-title mb-3">A smart & modern attendance solution for your workplace.</h3>
            <p class="card-text mb-4">A fast, safe & easy way for you and your employees to manage, record, 
              capture, and track time spent at work, from any device, wherever you are with advance features 
              like attendance with selfie, geo-location or geotagging</p>
              <p class="card-text mb-4">Capture employee attendance directly from their mobile device with their location, so no need to invest in a bio-metric attendance machine, 
                keeping your workplace and your workers safe especially during these trying times with COVID-19.</p>
          </card>

        </div>

        <div class="col-lg-6">

          <!-- Basic with card title -->
          <card>
            <!-- Card body -->
            <h3 class="card-title mb-3">Getting started</h3>
            <div class="row align-items-center">
              <div class="col-auto">
                  <img alt="Image placeholder" src="img/location.png" width="50">
              </div>
              <div class="col ml--2">
                  <p class="card-text mb-4">Your device must be at the location that you intend to use it to fetch the your location data for 
                    geo-fencing for on premise clocking in.</p>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-auto">
                  <img alt="Image placeholder" src="img/device.png" width="50">
              </div>
              <div class="col ml--2">
                  <p class="card-text mb-4">Login in to your admin dashboard, click on branches to list your branches with their branch code.</p>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-auto">
                  <img alt="Image placeholder" src="img/branch_code.png" width="50">
              </div>
              <div class="col ml--2">
                  <p class="card-text mb-4">Enter the branch code that you want you use the device which must be same as the location you are setting 
                    up the device and using from.</p>
              </div>
            </div>
            
          </card>

        </div>

      </div>
    </div>
    

  </div>
</template>
<script>

  // Components
  import BaseHeader from '@/components/BaseHeader';


  export default {
    components: {
      BaseHeader,
    },
    data() {
      return {
        company: JSON.parse(localStorage.getItem('company')),
        sessionData: JSON.parse(localStorage.getItem('session')),
        client: JSON.parse(localStorage.getItem('client')),
        totalStaff: '0',
        totalAttendance: '0',
        totalBranches: '0',
        totalAbsent: '0'
      };
    },
    methods: {

      async getDashboardStats(){
        const loading = this.$loading({
          lock: true,
          // text: 'Loading',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });
        var clientData = {
          "client": this.client.info.client_id,
          "user": this.client.info.user_id
        };
        var resData = await this.$http.post(this.$baseUrl+"ClientDash:stats&token="+this.sessionData.token, clientData).then(res =>{
          // console.log(res.data.length)
          return res.data;
        }).catch(err=>{
          return err;
        });

        //
        if (JSON.stringify(resData).includes("staff_count")) {
          this.totalStaff = resData.data.staff_count;
          this.totalBranches = resData.data.branches_count;
          this.totalAttendance = resData.data.attendance_count_today;
          this.totalAbsent = resData.data.absent_count;
          loading.close();
        }
        // console.log(resData.data)
         // //Error
        if (resData != "" && JSON.stringify(resData).includes("ERR")) {
          loading.close();
          //show error notification
          this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      }
    },
    created() {
      
      if (this.sessionData != null && this.company != null && this.client != null) {
        this.getDashboardStats()
      }
      
      // console.log(this.value)
    },
  };
</script>
<style></style>
