<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Profile</h3>
      </div>
      <div class="col-4 text-right">
        <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
      </div>
    </div>
      <h6 class="heading-small text-muted mb-4">User information</h6>

      <div>
        <div class="row">
          <div class="col-lg-6">
            <span class="h6 surtitle text-muted">First name</span>
            <quick-edit v-model="sessionData.info.first_name" @input="updateProfile(sessionData.info.id, sessionData.info.first_name,'first_name')" ></quick-edit>
          </div>
          <div class="col-lg-6">
            <span class="h6 surtitle text-muted">Last name</span>
            <quick-edit v-model="sessionData.info.last_name" @input="updateProfile(sessionData.info.id, sessionData.info.last_name,'last_name')" ></quick-edit>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <span class="h6 surtitle text-muted">Username</span>
            <quick-edit v-model="sessionData.info.username" @input="updateProfile(sessionData.info.id, sessionData.info.username,'username')" ></quick-edit>
          </div>
        </div>
      </div>
      <hr class="my-4">

      <!-- Address -->
      <h6 class="heading-small text-muted mb-4">Contact information</h6>

      <div class="">
        <div class="row">
          <div class="col-lg-6">
            <span class="h6 surtitle text-muted">Email</span>
            <quick-edit v-model="sessionData.info.email" @input="updateProfile(sessionData.info.id, sessionData.info.email,'email')"></quick-edit>
          </div>
          <div class="col-lg-6">
            <span class="h6 surtitle text-muted">Phone number</span>
            <quick-edit v-model="sessionData.info.phone_number" @input="updateProfile(sessionData.info.id, sessionData.info.phone_number,'phone_number')"></quick-edit>
          </div>
        </div>
      </div>
  </card>
</template>
<script>
export default {
  data() {
    return {
      sessionData: JSON.parse(localStorage.getItem('session')),
    };
  },
  methods: {
    updateProfile: async function(userId, data, type) {
     const loading = this.$loading({
        lock: true,
        text: 'Updating...',
        // spinner: 'el-icon-loading',
        background: 'rgb(255, 255, 255, 0.7)'
      });

      var updateData = {
        "id": userId,
        [type] : data,
      }
     
      

      var updateDataRes = await this.$http.post(this.$baseUrl+"users:save&token="+this.sessionData.token,updateData).then(res => {
        return res.data;
      }).catch(err=>{
        return err;
        // console.log(err);
      });

      if (JSON.stringify(updateDataRes).includes("id")) {
        // Get the existing data
        var userDataUpdate= JSON.parse(localStorage.getItem('session')).info;
        userDataUpdate[type.toString()] = data;

        localStorage.setItem('session', JSON.stringify({
          token: this.sessionData.token,
          info: userDataUpdate
        }));
        location.reload();
        loading.close();
      }
      // console.log(updateDataRes);
      if (JSON.stringify(updateDataRes).includes("ERR")) {
        // console.log(resData)
        //show error notification
        loading.close();
        this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
          position: "top-right",
          // timeout: 5000,
          closeOnClick: true,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
      }
    },
    //
  }
};
</script>
<style></style>
