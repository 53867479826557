<template>
  <div class="content">
    <base-header class="pb-6" type="primary">
      <div class="row align-items-center py-4">
      </div>
    </base-header>
    <div class="container-fluid mt--6 mb-9">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0">Todays Attendance Logs</h3>
              </div>
              <div class="col-6 text-right">
                <el-tooltip content="Export" placement="top">
                  <base-button type="neutral" icon size="sm">
                    <span class="btn-inner--icon"><i class="fas fa-file-export"></i></span>
                    <span class="btn-inner--text">Export</span>
                  </base-button>
                </el-tooltip>
              </div>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
            <div class="row">
               <div class="col-sm-12 col-md-3">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              
               <div class="col-sm-6 col-md-4">
                <el-select v-model="filterValue" placeholder="Select" @change="filterValue == 'range' ? showDatefileds() : fetchAttendanceLogs(filterValue)" ref="optiosValue">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
              
              <div>
                <base-input alternative v-model="searchQuery"
                    prepend-icon="fas fa-search"
                    placeholder="Search by name...">
                </base-input>
              </div>
            </div>
            <el-table :data="queriedData"
                      row-key="id"
                      header-row-class-name="thead-light"
                      @sort-change="sortChange">
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{selectedRows.length}} rows selected
                </span>
              </p>

            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>

      <div>
        <modal :show.sync="modals.dateRangeModal"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-sm">
            <card type="secondary" shadow
                  header-classes="bg-white pb-5"
                  body-classes="px-lg-5 py-lg-5"
                  class="border-0">
                <template>
                  <div class="row mb-2">
                    <div class="col text-muted">
                        <h2>Date Range</h2>
                    </div>
                  
                    <div class="col-auto">
                      <base-button @click="closeRangeModal()" outline type="danger" size="sm" class="btn-icon-only rounded-circle badge-danger border-0">
                        <span class="btn-inner--icon"><i class="fas fa-times"></i></span>
                      </base-button>
                    </div>
                  </div>
                </template>
                <template>
                    <el-date-picker
                        v-model="dateValue"
                        class="w-100"
                        type="daterange"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        format="dd-MM-yyyy"
                        >
                      </el-date-picker>
                      <base-button type="primary" class="mt-4 w-100" @click="getLogsForRange()">Search</base-button>
                </template>
            </card>
        </modal>
      </div>
    </div>
  </div>
  
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from '@/components';
import clientPaginationMixin from './Tables/PaginatedTables/clientPaginationMixin';

export default {
  mixins: [clientPaginationMixin],
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      modals: {
        dateRangeModal: false,
      },
      filterValue: 'today',
      dateValue: '',
      options: [
        {
        value: 'all',
        label: 'All'
        }, {
          value: 'today',
          label: 'Today'
        }, {
          value: 'range',
          label: 'Date'
        }
      ],
      fields : ['full_name'],
      propsToSearch: ['full_name'],
      company: JSON.parse(localStorage.getItem('company')),
      sessionData: JSON.parse(localStorage.getItem('session')),
      client: JSON.parse(localStorage.getItem('client')),
      tableColumns: [
        
        {
          prop: 'full_name',
          label: 'Name',
          // minWidth: 220,
          sortable: true
        },
        {
          prop: 'branch_name',
          label: 'Branch',
          sortable: true
        },
        {
          prop: 'clock_in_time',
          label: 'Clocked in',
          minWidth: 135,
          sortable: true
        },
        {
          prop: 'clock_out_time',
          label: 'Clocked out',
          minWidth: 135,
          sortable: true
        },
        {
          prop: 'time_spent',
          label: 'Total hours',
          minWidth: 100,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      search: '',
    };
  },
  methods: {
     selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    showDatefileds(){
      this.modals.dateRangeModal = true;
    },
    closeRangeModal(){
      this.modals.dateRangeModal = false; 
      this.filterValue = 'today'
    },
    async getLogsForRange(){
      // hide modal
      this.modals.dateRangeModal = false;
      var selectedStartdate = this.$moment(this.dateValue[0]).format('YYYY-MM-DD');
      var selectedEnddate = this.$moment(this.dateValue[1]).format('YYYY-MM-DD');
      //fetch logs
      await this.fetchAttendanceLogs(this.filterValue,selectedStartdate,selectedEnddate);
    },
    async getLogs(){
      console.log(this.filterType)
    },
    fetchAttendanceLogs: async function(filterType,startDate,endDate){

      const loading = this.$loading({
        lock: true,
        // text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgb(255, 255, 255, 0.7)'
      });
      // if type == range
      // start_date=>$start_date-(2020-02-05)
      // end_date=>$end_date-(2020-02-11)
      var clientData;

      if (filterType == undefined) {
        filterType = 'today';
      }
      if (filterType == 'range') {
        clientData = {
          "client": this.client.info.client_id,
          "type": filterType, //'all' || 'range'
          "start_date": startDate,
          "end_date": endDate
        };
      }else{
        clientData = {
          "client": this.client.info.client_id,
          "type": filterType //'all' || 'range'
        };
      }
      
        
      var resData = await this.$http.post(this.$baseUrl+"StaffAttendances:fetch&token="+this.sessionData.token, clientData).then(res =>{
        return res.data;
      }).catch(err=>{
        return err;
      });
      // console.log(resData);

      if (resData != "" && JSON.stringify(resData).includes("ERR")) {
        loading.close();
        //show error notification
        this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
          position: "top-right",
          // timeout: 5000,
          closeOnClick: true,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
      }else{
        loading.close();
        this.tableData = resData.data;
      }
    },
  
    
  },
  created() {
    this.fetchAttendanceLogs()
    // this.fetchAttendanceLog()
  },
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
