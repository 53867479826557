<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand"
    :class="{'bg-primary navbar-dark': type === 'default'}"
  >
    <!-- Search form -->
    <!-- <form class="navbar-search form-inline mr-sm-3"
          :class="{'navbar-search-light': type === 'default', 'navbar-search-dark': type === 'light'}"
          id="navbar-search-main">
      <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </form> -->
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler"
             :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
             @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <!-- <li class="nav-item d-sm-none">
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li> -->
      
      
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle bg-default">
                    <!-- <img alt="Image placeholder" src="img/theme/team-4.jpg"> -->
                    {{sessionData != null ? sessionData.info.first_name[0].toUpperCase() : ''}}
                  </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{sessionData != null ? sessionData.info.first_name+" "+sessionData.info.last_name : ''}}</span>
            </div>
          </div>
        </a>

        <template>

          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <a href="#/account"  class="dropdown-item">
            <i class="far fa-user-circle"></i>
            <span>Account</span>
          </a>
          <!-- <a href="#!" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>Settings</span>
          </a>
          <a href="#!" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Activity</span>
          </a>
          <a href="#!" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </a> -->
          <div class="dropdown-divider"></div>
          <a href="#!" @click.prevent="logMeOut()" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>

        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';

export default {
  components: {
    BaseNav
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      sessionData: JSON.parse(localStorage.getItem('session')),
      firstname:'',
      lastname: ''
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    //session check
    async checkSession(){
      if (this.sessionData == null) {
        localStorage.clear('session');
        this.$router.push({name: 'Login'});
      }
    },
    //
    logMeOut: async function(){
      const loading = this.$loading({
        lock: true,
        text: 'Logging out',
        background: 'rgb(255, 255, 255, 0.7)'
      }); 

      await this.$http.post(this.$baseUrl+"sessions:logout&token="+this.sessionData.token).then(res => {
          var resData = res.data;
          
          localStorage.clear('session');
          this.$router.push({name: 'Login'});
          loading.close();

          if (JSON.stringify(resData).includes("warning")) {
            localStorage.clear();
            this.$router.push({name: 'Login'});
            loading.close();
          }
        }).catch(err=>{
          console.log(err);
        });
    }
  },
  created() {
     this.checkSession();
  },
};
</script>
