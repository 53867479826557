<template>
  <div>
    <base-header class="pb-6" type="">
    <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Account</h6>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!-- <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button> -->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6 mb-9">
      <div class="row">
        <div class="col-xl-4 order-xl-2">
          <!-- Progress track -->
          <card>
             <h6 class="heading-small text-muted mb-2">Company information</h6>
            <div>
              <span class="h6 surtitle text-muted">Name</span>
              <quick-edit v-model="company.info.name" @input="updateCompany(company.info.id, company.info.name, 'name')"></quick-edit>
            </div>

            <div>
              <span class="h6 surtitle text-muted">Logo</span>
              <hr class="my-2">
              <small class="text-muted font-italic">Upload a transparent PNG cropped to the edges of your logo up to 1000px wide or 600px tall.</small>
              <div class="img-logo-preview mt-2">
                 <small class="text-muted font-italic w-100" v-show="showEmptyLabel">Your Logo</small>
                <img :src="fileUrl" style="height:140px"/>
              </div>
              <small class="text-muted font-italic mt-2">Must be PNG files with a size less than/equal 1MB</small>
              <div class="row pl-4 pt-4">
                <div>
                  <base-button class="rounded-pill"  type="primary"  @click="handleClick">
                    <span class="btn-inner--text">select file</span>
                  </base-button>
                  <input type="file" ref="upload" style="display: none" @change="fileChange" accept="mage/png"/>
                </div>
                <base-button outline  type="success"  class="badge-success rounded-pill" @click="onLogoUpload">
                  Save
                </base-button>
                <base-button outline  type="danger"  class="badge-danger rounded-pill">
                    <span class="btn-inner--icon">
                      <i class="fas fa-trash"></i>
                    </span>
                </base-button>
              </div>
            </div>
          </card>
        </div>
        <div class="col-xl-8 order-xl-1">
          <div class="row">
          </div>
          <edit-profile-form></edit-profile-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditProfileForm.vue';
  import BaseHeader from '@/components/BaseHeader';

  export default {
    components: {
      EditProfileForm,
      BaseHeader
    },
    data(){
      return{
        sessionData: JSON.parse(localStorage.getItem('session')),
        company: JSON.parse(localStorage.getItem('company')),
        showEmptyLabel: true,
        fileUrl: '',
        file:''
      }
    },
    methods:{
      handleClick() {
        if (!this.disabled) {
          this.$refs.upload.value = null;
          this.$refs.upload.click();
        }
      },
      fileChange(file){
        //  const url = ;
        // console.log(file.target.files[0])

        this.showEmptyLabel = false;
        this.fileUrl = URL.createObjectURL(file.target.files[0]);
        this.file = file.target.files[0];
      },
      onLogoUpload: async function(){
        if (this.file == '') {
          
        }else{
          const loading = this.$loading({
            lock: true,
            text: 'Updating...',
            // spinner: 'el-icon-loading',
            background: 'rgb(255, 255, 255, 0.7)'
          });

          var formData = new FormData();
          formData.append('file', this.file);
          
          var uploadLogoRes = await this.$http.post(
            this.$baseUrl+"blobs:save&key=file&token="+this.sessionData.token+"&user_id="+this.sessionData.info.id,
            formData,{
              headers: {
                "Content-Type": "multipart/form-data"
              }}).then(res => {
            return res.data;
          }).catch(err=>{
            return err;
            // console.log(err);
          });

          if (JSON.stringify(uploadLogoRes).includes("id")) {
            var updateData = {
              "id": this.company.info.id,
              "logo_id" : uploadLogoRes.id,
            }
            //update companies table
            var updateDataRes = await this.$http.post(this.$baseUrl+"companies:save&token="+this.sessionData.token,updateData).then(res => {
              return res.data;
            }).catch(err=>{
              return err;
              // console.log(err);
            });
          
            if (JSON.stringify(updateDataRes).includes("id")) {
              // Get the existing data
              var companyDataUpdate= JSON.parse(localStorage.getItem('company')).info;
              companyDataUpdate['logo_id'] = uploadLogoRes.id;

              localStorage.setItem('company', JSON.stringify({
                info: companyDataUpdate
              }));

              // this.getLogo()
              loading.close();
            }

            if (JSON.stringify(updateDataRes).includes("ERR")) {
            //show error notification
              loading.close();
              this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
                position: "top-right",
                // timeout: 5000,
                closeOnClick: true,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
              
            }
              
            
          }

          if (JSON.stringify(uploadLogoRes).includes("ERR")) {
            //show error notification
            loading.close();
            this.$toast.error("Logo upload failed, Kindly check and try again.", {
              position: "top-right",
              // timeout: 5000,
              closeOnClick: true,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
          }
        }
        

      },

      updateCompany: async function(companyId, data, type) {
        const loading = this.$loading({
          lock: true,
          text: 'Updating...',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });

        var updateData = {
          "id": companyId,
          [type] : data,
        }
     
      
        var updateDataRes = await this.$http.post(this.$baseUrl+"companies:save&token="+this.sessionData.token,updateData).then(res => {
          return res.data;
        }).catch(err=>{
          return err;
          // console.log(err);
        });

        if (JSON.stringify(updateDataRes).includes("id")) {
          // Get the existing data
          var companyDataUpdate= JSON.parse(localStorage.getItem('company')).info;
          companyDataUpdate[type.toString()] = data;

          localStorage.setItem('company', JSON.stringify({
            info: companyDataUpdate
          }));
          // location.reload();
          loading.close();
        }
        // console.log(updateDataRes);
        if (JSON.stringify(updateDataRes).includes("ERR")) {
          // console.log(resData)
          //show error notification
          loading.close();
          this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
            position: "top-right",
            // timeout: 5000,
            closeOnClick: true,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          
        }
      },
      getLogo: async function(){
        if (this.company.info.logo_id != null) {
          const loading = this.$loading({
            lock: true,
            text: 'Updating...',
            // spinner: 'el-icon-loading',
            background: 'rgb(255, 255, 255, 0.7)'
          });
          var logoImage = await this.$http.get(this.$baseUrl+"blobs:list&token="+this.sessionData.token+"&id="+this.company.info.logo_id).then(res => {
            return res.data;
            }).catch(err=>{
            return err;
            // console.log(err);
          });

          if (JSON.stringify(logoImage).includes("id")) {
            this.showEmptyLabel = false;
            this.fileUrl = this.$cdnUrl+logoImage[0].url;
            this.file = '';
            // location.reload();
            loading.close();
          }
          // console.log(updateDataRes);
          if (JSON.stringify(logoImage).includes("ERR")) {
            // console.log(resData)
            //show error notification
            loading.close();
            this.$toast.error("Oops sorry, an error occured, Kindly try again.", {
              position: "top-right",
              // timeout: 5000,
              closeOnClick: true,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            
          }
          
        }
        
      }

    },
    created() {
      this.getLogo()
    },
  };
</script>
<style>
  .img-logo-preview {
    padding: 1rem;
    height: 150px;
    background-color: #fff;
    border: 1px dashed #dee2e6;
    border-radius: 0.375rem;
    text-align: center;
    color: #8898aa;
    z-index: 999;
    display: flex;
    align-items: center;
  }
</style>
