var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"logo":"img/brand/logo.png"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Welcome',
          icon: 'ni ni-ungroup text-primary',
          path: '/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Attendance log',
          icon: 'fas fa-walking text-primary', 
          path: '/attendance-log'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Staff',
          icon: 'ni ni-badge text-primary',
          path: '/staff'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Branches',
          icon: 'ni ni-shop text-primary',
          path: '/branches'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Account',
          icon: 'far fa-user-circle text-primary', 
          path: '/account'
        }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"})])],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }