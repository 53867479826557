<template>
  <div>
    <!-- Page content -->
    <div class="container mt--5 pb-5 mt-lg-5 py-7">
      <div class="row justify-content-center bubble_bg">
        <div class="col-lg-5 col-md-7">
          <div class="card mb-0 mt-5 shadow-none bg-transparent">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-right text-muted mb-4">
                <h2>
                  <div><span>Password Reset |</span> <img src="img/brand/logo.png" alt="Klok logo" width="80" class="img-fluid"></div>
                 
                </h2>
              </div>
              <div v-if="!showErrorMessage">
                <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                  <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <base-input alternative
                                class="mb-3"
                                name="password"
                                :rules="{required: true, min: 8,  regex: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/}"
                                prepend-icon="ni ni-lock-circle-open"
                                type="password"
                                placeholder="New Password"
                                v-model="model.newpassword">
                    </base-input>

                    <base-input alternative
                                class="mb-3"
                                name="confirm password"
                                :rules="{required: true, confirmed: 'password',}"
                                prepend-icon="ni ni-lock-circle-open"
                                type="password"
                                
                                placeholder="Confirm Password"
                                v-model="model.repeatpassword">
                    </base-input>
                    <div class="text-right">
                      <base-button type="primary" native-type="submit" class="my-4 new_primary_bg rounded_btn">Save</base-button>
                    </div>
                  </form>
                </validation-observer>
              </div>
              <!-- Password reset link sent -->
              <div v-if="showErrorMessage" class="py-5">
                <p class="font-weight-bold">This password reset session has expired.</p>
              </div>

              <div class="row mb-6">
                <div class="col text-center">
                  <router-link to="/login"><small><b>« back to LOGIN</b></small></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        model: {
          newpassword: '',
          repeatpassword: ''
        },
        fullscreenLoading: false,
        showErrorMessage: false
      };
    },
    methods: {
      async onSubmit() {
         
          if (this.model.newpassword != this.model.repeatpassword) {
              //show error notification
              this.$toast.error('Passwords do not match.', {
                position: "top-center",
                // timeout: 5000,
                closeOnClick: true,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });

          }else{
            // console.log(this.model.newpassword)

            var resetData = {
              "code": this.$route.query.verify,
              "new_password": this.model.newpassword
            };
            // console.log(resetData)
            // // this will be called only after form is valid.
            const loading = this.$loading({
              lock: true,
              // text: 'Loading',
              // spinner: 'el-icon-loading',
              background: 'rgb(255, 255, 255, 0.7)'
            });

            var passwordUpdate = await this.$http.post(this.$baseUrl+"PasswordUpdate:users&token="+this.$publicToken,resetData).then(res =>{
              // console.log(res);
              return res.data;
            }).catch(err=>{
              return err
              // console.log(err);
            });
            // console.log(passwordUpdate);

            // //
            if (passwordUpdate.MSG == "Password Updated Successfully") {
              this.$toast.success(passwordUpdate.MSG, {
                position: "top-center",
                closeOnClick: true,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
              //
              this.$router.push({path: '/login'});
              loading.close()
            }
            
            if (JSON.stringify(passwordUpdate).includes("MSG") && passwordUpdate.MSG != "Password Updated Successfully") {
              loading.close();

              this.$toast.error(passwordUpdate.MSG, {
                position: "top-center",
                closeOnClick: true,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
            }

            

            if (JSON.stringify(passwordUpdate).includes("ERR")) {
              loading.close();
              
              //show error notification
              this.$toast.error('Something went wrong. Kindly check and try again.', {
                position: "top-center",
                // timeout: 5000,
                closeOnClick: true,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: true,
                rtl: false
              });
              
            }
          }
        
      },
      async verifyCode(){
        // console.log(this.$route.query.verify)
        var verifyData = {
          "code": this.$route.query.verify
          // "code": 'c56389omsggg'
        };
        // this will be called only after form is valid.
        const loading = this.$loading({
          lock: true,
          // text: 'Loading',
          // spinner: 'el-icon-loading',
          background: 'rgb(255, 255, 255, 0.7)'
        });
        var userVerify = await this.$http.post(this.$baseUrl+"VerifyCode:users&token="+this.$publicToken,verifyData).then(res =>{
          // console.log(res);
          return res.data;
        }).catch(err=>{
          return err
          // console.log(err);
        });
        // console.log(userVerify);
        // //
        if (userVerify.MSG == "Valid token") {
          loading.close();
        }

        if (userVerify.MSG == "Invalid token") {
          loading.close();
          this.showErrorMessage = true;
        }
        
        // ResetPassword.vue?bcb7:146 {res: 500, MSG: "Invalid token"}
      }
    },
    created() {
    this.verifyCode();
  },
  };
</script>
<style>
 .new_primary_bg{
   background-color: #0023FF !important;
 }
 .bubble_bg{
   background-image: url('../../../public/img/bubble.png');
   background-position: 50px 50px;
   background-repeat: no-repeat;
 }
 .rounded_btn{
   border-radius: 10rem !important;
 }
</style>
